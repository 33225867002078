html,
body {
    // overflow-x: hidden;
    width: 100%;
    /* You may also want to try adding:*/
    margin: 0;
    padding: 0;
}

// this is the place for your components / CSS
.time-select {
    width: auto;
    height: auto;
}

.calendar-available {
    background-color: #f0f0ff;
    // border-left: 8px solid #636aee;
}

.is-disabled {
    opacity: 0.7;
}

.MuiSlider-root {
    color: $primary;
    height: 8px;
}


.CalendarDay__today {
    /*Your styles here*/
    font-weight: bold;
    background-color: lighten($primary, 30%);
}

.CalendarDay__selected {
    /*Your styles here*/
    background-color: $primary;
}

.place-suggestion-li {

    &:hover {
        background-color: lighten($primary, 30%);
    }
}

.signup-toast {
    position: fixed;
    left: 50%;
    top: calc(#{$navbarHeight} + 1rem);
    z-index: $zindex-tooltip;
    transform: translateX(-50%);
}

.info-toast {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: $zindex-tooltip;
}

.row-cta {
    white-space: nowrap;
    font-size: $font-size-sm;
    padding: 0;
}

.dashboard-title {
    font-size: 1.25rem;
    font-size: max(1.0rem, min(1.25rem, 3.5vw));
}

.color-legend {
    display: inline-block;
    width: 2rem;
    height: 1rem;
    border-radius: 2px;
    border: 1px solid gray;
}

.youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 0px;
}

.youtube-container iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.fancy-underline-lg {
    text-decoration: underline;
    text-decoration-color: lighten($primary, 10%);
    text-decoration-thickness: 10px !important;
    text-decoration-skip-ink: none;
    text-underline-position: under;
}

.fancy-underline {
    text-decoration: underline;
    text-decoration-color: lighten($primary, 10%);
    text-decoration-thickness: 5px !important;
    text-decoration-skip-ink: none;
    text-underline-position: under;
}

.ul-primary {
    list-style: none;

    li {
        position: relative;
        padding-left: 36px;
    }
}

.ul-primary li::before {
    position: absolute;
    content: "";
    left: 0;
    top: 4px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC41NCIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04Ljk5OTkgMTYuMkw0Ljc5OTkgMTJMMy4zOTk5IDEzLjRMOC45OTk5IDE5TDIwLjk5OTkgNi45OTk5OEwxOS41OTk5IDUuNTk5OThMOC45OTk5IDE2LjJaIiBmaWxsPSIjNjM2YWVlIi8+Cjwvc3ZnPgo=) no-repeat;
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold;
    /* Change the color */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 30px;
    height: 30px;
    background-size: contain;
    /* Also needed for space (tweak if needed) */

}

.pre-wrap {
    white-space: pre-wrap;
}

.instagram {
    border: 0;
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.scroll-margin-top {
    scroll-margin-top: 10rem;
}

.class-cover {
    height: auto;
    width: 100%;
    // max-width: 940px;
    max-height: 540px;
    object-fit: cover;
}

.pointer {
    cursor: pointer;
}

.below-header {
    top: $headerHeight;
}

#class_search_form_date {
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-weight: bold;
    font-size: 0.8rem;
}

.table tbody tr.highlight td {
    background-color: $primary;
    color: white;
    font-weight: $font-weight-bold;
}


.booking-owner {
    overflow: hidden;
    max-width: 75ch;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-not-read {
    border-left: 5px solid $primary;
}

.retreat-border {
    border-left: 5px solid $primary;
    border-right: 5px solid $primary;
}

.white-space-nowrap {
    width: 1px;
    white-space: nowrap;
}


.hover-actions {
    display: none;
    position: absolute;

    &.show {
        display: inline-block;

        &+.hover-hide {
            display: none;
        }
    }
}

.hover-actions-trigger {
    position: relative;

    // Style for email Inbox
    .inbox-link {
        color: inherit;
        text-decoration: none;
    }

    @include hover-focus {
        .hover-actions {
            z-index: 1;
            display: flex;

            &.btn {
                display: inline-block;
            }
        }

        .hover-hide {
            display: none;
        }
    }
}

.hover-actions-trigger.btn-reveal-trigger {
    .hover-actions {
        right: 3.5rem;

        [dir='rtl'] & {
            right: 3.5rem;
        }
    }
}

.icon-item {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // transition: $transition-base;
    height: 2.5rem;
    width: 2.5rem;
    border: 0;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
    // @include hover-focus {
    //     background-color: var(--#{$prefix}gray-200) !important;
    // }

    &.icon-item-sm {
        height: 1.875rem;
        width: 1.875rem;
    }

    &.icon-item-lg {
        height: 2.75rem;
        width: 2.75rem;
    }
}

#my_clients th {
    vertical-align: middle;
}

#my_clients td {
    vertical-align: middle;
}


.simplebar-track {
    top: 0 !important;
}

// .simplebar-scrollbar::before {
//     background-image: rgb(0,0,0);
// }

.simplebar-scrollbar::before {
    // background-color: linear-gradient(-131deg, rgb(231, 176, 43) 0%, $primary 100%) !important;
    // background-image: linear-gradient(-131deg, $primary-200 0%, $primary 100%) !important;
    background-color: $primary !important;
  }

.cell-sm {
  @include media-breakpoint-down(sm) {
    max-width: 9rem;
  }
}

.inactive-link {
    pointer-events: none;
    cursor: default;
 }


 .blur {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
 }

 .language-picker {
    display: inline-block;
    position: relative;
    font-size: 16px;
  }
  
  .language-select {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    color: #333;
    font-size: 16px;
    appearance: none;
    cursor: pointer;
  }
  
  .language-select:focus {
    outline: none;
    border-color: #0070f3;
  }
  
  .language-select option {
    padding: 8px;
    font-size: 16px;
  }
  